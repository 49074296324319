import React, { useState } from "react";
import "../css/style.css";
import "../css/responsive.css";

import Header from "../components/Header";
import Banner from "../components/Banner";
import About from "../components/About";
import Services from "../components/Services";
import Footer from "../components/Footer";
import Entreprise from "../components/Entreprise";
import Contact from "../components/Contact";
import NotreEaquipe from "../components/NotreEquipe";

const Main = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="main">
      <Header isOpen={isOpen} toggleMenu={toggleMenu} />
      <Banner isOpen={isOpen} />
      <About />
      <NotreEaquipe />
      <Services />
      <Entreprise />
      <Contact />
      <Footer />
    </div>
  );
};

export default Main;
