import React from 'react';
import { Container } from 'react-bootstrap';
import Navigation from './Nav';

const Header = ({ isOpen, toggleMenu }) => {
    return (
        <header className="">
            <Container>
                <Navigation isOpen={isOpen} toggleMenu={toggleMenu} />
            </Container>
        </header>
    );
}

export default Header;
