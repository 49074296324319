import React, { useEffect, useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { HashLink as Link } from "react-router-hash-link";
import "../css/hamburgers.css";
import logo from "../assets/images/logo.png";

const Navigation = ({ isOpen, toggleMenu }) => {
  const [menuClass, setMenuClass] = useState("");

  useEffect(() => {
    if (isOpen) {
      setMenuClass("show");
    } else {
      setMenuClass("hide");
    }
  }, [isOpen]);

  return (
    <>
      <Navbar expand="lg">
        <Navbar.Brand as={Link} to="/">
          <div className="cercle mt-5">
            <img src={logo} alt="logo site" />
          </div>
        </Navbar.Brand>
        <button
          className={`hamburger hamburger--collapse mobile ${
            isOpen ? "is-active" : ""
          }`}
          type="button"
          onClick={toggleMenu}
          aria-controls="basic-navbar-nav"
          aria-expanded={isOpen}
          aria-label="Toggle navigation"
        >
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </button>
        <Navbar.Collapse id="basic-navbar-nav" className={`${menuClass}`}>
          <div className="mt-5">
            <Nav className="ml-auto">
              <Nav.Link as={Link} to="/#about" onClick={toggleMenu}>
                À PROPOS
              </Nav.Link>
              <Nav.Link as={Link} to="/#notre equipe" onClick={toggleMenu}>
                NOTRE ÉQUIPE
              </Nav.Link>
              <Nav.Link as={Link} to="/#services" onClick={toggleMenu}>
                NOS SERVICES
              </Nav.Link>
              <Nav.Link as={Link} to="/#entreprises" onClick={toggleMenu}>
                ENTREPRISES
              </Nav.Link>
              <Nav.Link as={Link} to="/#contact" onClick={toggleMenu}>
                CONTACT
              </Nav.Link>
            </Nav>
          </div>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default Navigation;
