import logo from './logo.svg';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Main from './pages/Main';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/style.css';
import "./css/responsive.css";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Main />}></Route>
        {/* <Route path="/contact/" element={<Contact />}></Route> */}
        {/* <Route path="*" element={<Erreur />}></Route> */}
      </Routes>
    </BrowserRouter >
  );
}

export default App;
