import { Container, Row, Col } from "react-bootstrap";
import logo from "../assets/images/Entreprise/3.svg";
import img from "../assets/images/Entreprise/Groupe-masques-2.png";

// import chapeau from "../assets/images/Entreprise/right/casquette-de-graduation.png";
// import artifice from "../assets/images/Entreprise/right/feux-dartifice.png";
// import martini from "../assets/images/Entreprise/right/martini.png";
// import promotion from "../assets/images/Entreprise/right/promotion.png";

import icon1 from "../assets/images/Entreprise/right/icon1.svg";
import icon2 from "../assets/images/Entreprise/right/icon2.svg";
import icon3 from "../assets/images/Entreprise/right/icon3.svg";

import ThemeProvider from "react-bootstrap/ThemeProvider";

const CustomBreakpoints = {
  breakpoints: ["xs", "sm", "md", "lg", "xl"],
};

const Entreprise = () => {
  return (
    <section id="entreprises">
      <ThemeProvider breakpoints={CustomBreakpoints.breakpoints}>
        <Container>
          <Row className="section-header align-items-center pb-5">
            <Col lg={8} md={8} xs={8} className="d-flex flex-column">
              <h2>ESPACE ENTREPRISES</h2>
              <h3>
              Repas d’affaires, réunions et événements sur mesure
              </h3>
              <p> À la Brasserie 393, nous comprenons l’importance de créer des
                moments privilégiés pour les entreprises, que ce soit pour des
                repas d’affaires, des réunions informelles ou des événements
                professionnels. <br/> 
                Située en face de l’aéroport de Nice et au cœur
                du campus EDHEC, notre brasserie est l’endroit idéal pour
                accueillir vos collaborateurs, clients et partenaires.</p>
            </Col>
          </Row>
          <Row className="entreprise-container pb-5">
            <Col lg={6} md={12}>
              <img className="img-entreprise" src={img} alt="de fond" />
            </Col>
            <Col lg={4} md={12} className="info-entreprise">
              <Row className="flex-column">
                <Col>
                  <img className="entreprise-icon" src={icon1} alt="artifice" />
                </Col>
                <Col>
                  <h3>DÉJEUNERS D'AFFAIRES</h3>
                </Col>
                <Col>
                  <p>
                  Invitez vos clients ou partenaires dans un cadre convivial et professionnel. <br/>
                  Notre carte variée et nos plats frais et équilibrés sont parfaits pour des repas d’affaires.
                  </p>
                </Col>
              </Row>
              <Row className="flex-column pt-5">
                <Col>
                  <img className="entreprise-icon" src={icon2} alt="chapeau" />
                </Col>
                <Col>
                  <h3>SERVICE TRAITEUR SUR MESURE</h3>
                </Col>
                <Col>
                  <p>
                    Organisez vos événements d’entreprise directement sur le campus ou dans vos locaux grâce à notre service traiteur. Nous proposons des solutions personnalisées pour vos séminaires, cocktails d’entreprise, ou réunions de CE.<br/>
                    <i>En savoir plus sur notre service traiteur avec : https://www.monarte-events.com/</i>
                  </p>
                </Col>
              </Row>
              <Row className="flex-column pt-5">
                <Col>
                  <img className="entreprise-icon" src={icon3} alt="martini" />
                </Col>
                <Col>
                  <h3>PRIVATISATION DE L'ESPACE</h3>
                </Col>
                <Col>
                  <p>
                  Vous organisez une réunion ou un événement professionnel sur le campus ?<br/> 
                  Nous mettons à disposition un espace privatisé pour accueillir vos collaborateurs ou partenaires dans une atmosphère chaleureuse et professionnelle.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="pt-5">
            <Col className="entreprise-event div-contact d-flex justify-content-center flex-column align-items-center flex-wrap ">
              <h3 className="white-text">ORGANISEZ VOS ÉVÉNEMENTS AVEC BRASSERIE 393</h3>
              <p className="white-text">
                Que ce soit pour un repas de groupe, une réunion ou un événement
                d’entreprise, notre équipe est là pour vous<br/> offrir des solutions
                adaptées à vos besoins.
                <br /> Contactez-nous dès maintenant pour discuter de votre
                projet et obtenir un devis personnalisé.
              </p>
              <a href="/#contact" className="l-btn-contact">
                <div className="btn-contact">
                  <span>CONTACT</span>
                </div>
              </a>
            </Col>
          </Row>
        </Container>
      </ThemeProvider>
    </section>
  );
};

export default Entreprise;
