import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import icone from "../assets/images/Banner/icon-bas.png";

const Banner = ({ isOpen }) => {
  return (
    <section className={`banner ${isOpen ? "blur-back" : ""}`} id="banner">
      <Container>
        <Row>
          <Col className="d-flex justify-content-center flex-column align-items-center flex-wrap">
            <h3 className="h3--banner">
              Votre espace gourmand à l'EDHEC, à deux pas de l'aéroport de Nice
            </h3>
            <h1>BIENVENUE A LA BRASSERIE 393</h1>
            <a href="/#about">
              {/* Ajouter des classes Bootstrap pour ajuster la taille de l'icône */}
              <img
                className="pt-5 icon-banner w-50 w-md-25" // Ajuste la largeur en fonction de l'écran
                src={icone}
                alt="Icone"
              />
            </a>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Banner;
