// @ts-nocheck
import { Col, Container, Row } from "react-bootstrap";
import img1 from "../assets/images/NotreEquipe/photo1.png";
import img2 from "../assets/images/NotreEquipe/photo3.png";
import img3 from "../assets/images/NotreEquipe/fleche-haut.png";
import img4 from "../assets/images/NotreEquipe/fleche-bas.png";
import React from "react";
import "../css/notreequipe.css";
const NotreEquipe = () => {
  return (
    <>
      <Container className="notreequipe-p">
        <div className="text-center">
          <h2 className="sous-titre mb-1 ">NOTRE EQUIPE</h2>
          <p className="mb-3 mt-4">
            Notre équipe de chefs passionnés et expérimentés travaille <br />
            avec des produits frais et de saison pour créer des menus qui
            émerveilleront vos papilles.
          </p>
          <p className="mb-1">
            Nous collaborons étroitement avec vous pour comprendre vos besoins
            et vos envies, <br />
            afin de vous proposer des solutions adaptées et créatives.
          </p>
        </div>

        <Row className="align-items-center">
          {/* Titre à gauche avec l'image en dessous */}
          <Col
            lg={3}
            md={3}
            sm={12}
            className="d-flex flex-column align-items-start"
          >
            <h4 className="title-left">Moises Oliveira</h4>
            <img src={img3} alt="fleche-haut" className="image3 my-2" />
          </Col>

          {/* Images côte à côte au centre */}
          <Col lg={6} md={6} sm={12}>
            <div className="d-flex justify-content-center">
              <img src={img1} alt="ambiance 1" className="image1 mx-3" />
              <img src={img2} alt="ambiance 2" className="image2 mx-3" />
            </div>
          </Col>

          {/* Image à droite avec le titre en dessous */}
          <Col
            lg={3}
            md={3}
            sm={12}
            className="d-flex flex-column align-items-end"
          >
            <img src={img4} alt="fleche-bas" className="image4 my-2" />
            <h4 className="text-end ms-5">Christophe Spisak</h4>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default NotreEquipe;
