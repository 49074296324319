import { Container, Row, Col, Button } from "react-bootstrap";

import ThemeProvider from "react-bootstrap/ThemeProvider";

const CustomBreakpoints = {
  breakpoints: ["xs", "sm", "md", "lg", "xl"],
};

const Services = () => {
  return (
    <section id="services">
      <ThemeProvider breakpoints={CustomBreakpoints.breakpoints}>
        <Container>
          <Row className="d-flex justify-content-end align-items-center section-header pb-5">
            <Col
              lg={12}
              md={12}
              xs={12}
              className="d-flex align-items-end flex-column"
            >
              <h2 className="h2--dore">NOS SERVICES</h2>
              <h3>Des services sur mesure pour tous vos besoins</h3>
            </Col>
          </Row>
          <Row className="card-container gx-3 justify-content-center">
            <Col
              lg={3}
              md={6}
              className="c-card c-card-desktop c-card-1 c-offset"
            >
              <h3>RESTAURATION</h3>
              <p>
                Venez savourer nos plats du jour, salades, et desserts, à
                déguster sur place.
                <br /> Notre menu est conçu pour satisfaire tous les goûts, que
                vous cherchiez un repas rapide ou une pause gourmande.
              </p>
            </Col>
            <Col lg={3} md={6} className="c-card c-card-desktop c-card-2">
              <h3>PETIT DÉJEUNER</h3>
              <p>
                Commencez votre journée du bon pied avec notre offre de petits
                déjeuners.
                <br /> Que vous soyez plutôt croissant et café ou jus de fruits
                frais et tartines, nous avons tout ce qu’il vous faut pour une
                matinée réussie.
              </p>
            </Col>
            <Col
              lg={3}
              md={6}
              className="c-card c-card-desktop c-card-3 c-offset"
            >
              <h3>SERVICE TRAITEUR</h3>
              <p>
                Notre service traiteur s’adapte à vos besoins, qu’il s’agisse
                d’un déjeuner d’affaires, d’une réunion de comité d’entreprise
                (CE) ou d’un événement étudiant.
                <br /> Pour en savoir plus, découvrez notre partenaire
                événementiel : https://www.monarte-events.com/
              </p>
            </Col>
          </Row>
          <Row>
            <Col className="div-contact d-flex justify-content-center flex-column align-items-center flex-wrap pt-5">
              <a href="/#contact" className="l-btn-contact">
                <div className="btn-contact">
                  <span>CONTACT</span>
                </div>
              </a>
            </Col>
          </Row>
        </Container>
      </ThemeProvider>
    </section>
  );
};

export default Services;
