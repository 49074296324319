import { Col, Container, Row } from "react-bootstrap";
// import singe from "../assets/images/About/1.svg";
import img1 from "../assets/images/About/right/img1.png";
import img2 from "../assets/images/About/right/img2.png";
import ThemeProvider from "react-bootstrap/ThemeProvider";
import React from "react";
import "../css/about.css";

const CustomBreakpoints = {
  breakpoints: ["xs", "sm", "md", "lg", "xl"],
};

const About = () => {
  return (
    <section id="about">
      <ThemeProvider breakpoints={CustomBreakpoints.breakpoints}>
        <div>
          <Container className="container-about about-section ">
            <Row>
              <Col lg={6} xs={12} className="pb-4 pe-5">
                <Row className="d-flex align-items-center section-header pt-5 pb-3 about_paragraph">
                  <Col lg={8} md={8} xs={8} className="offset-1">
                    <h2 className="titre-about h2--dore">À PROPOS</h2>
                  </Col>
                </Row>
                <Row>
                  <Col lg={11} md={10} xs={12} className="offset-1">
                    <p className="text about-p">
                      La Brasserie 393 est plus qu’un simple restaurant
                      universitaire. Nous vous offrons une expérience culinaire
                      unique avec des plats faits maison, préparés avec des
                      ingrédients frais et locaux.
                    </p>
                    <br />
                    <p className="about-p">
                      Notre menu varie chaque jour, pour répondre à toutes vos
                      envies, qu’il s’agisse d’un repas équilibré ou d’une pause
                      gourmande.
                    </p>
                    <br />
                    <p className="about-p">
                      Notre équipe met tout en œuvre pour garantir un service
                      rapide, chaleureux et adapté à votre emploi du temps
                      chargé. Nous avons également un espace agréable pour les
                      discussions entre collègues ou un moment de détente entre
                      deux cours.
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col lg={6} md={12} xs={12} className="container-right-fond">
                <div className="container-right">
                  <img src={img1} alt="ambiance 1" className="image1" />
                  <img src={img2} alt="ambiance 2" className="image2 desktop" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ThemeProvider>
    </section>
  );
};

export default About;
